// extracted by mini-css-extract-plugin
export var aboutUsPage = "about-module--about-us-page--510d7";
export var advantagesBlock = "about-module--advantages-block--73f7e";
export var bodyShadow = "about-module--body-shadow--8a644";
export var col6 = "about-module--col-6--8417f";
export var greenTitle = "about-module--green-title--876f9";
export var learnMore = "about-module--learn-more--564a7";
export var locationText = "about-module--location-text--de177";
export var memberInfo = "about-module--member-info--684c8";
export var orangeTitle = "about-module--orange-title--e8f44";
export var ourFounding = "about-module--our-founding--a18ce";
export var ourFoundingContent = "about-module--our-founding-content--eb7c2";
export var ourQuestion = "about-module--our-question--43145";
export var questionBlock = "about-module--question-block--9cc95";
export var questionNumber = "about-module--question-number--d6075";
export var questionTile = "about-module--question-tile--beffd";
export var questionTile1 = "about-module--question-tile-1--0c1ba";
export var questionTile2 = "about-module--question-tile-2--7e10e";
export var questionTile3 = "about-module--question-tile-3--dad08";
export var questionTile4 = "about-module--question-tile-4--c70f9";
export var seeHow = "about-module--see-how--2104f";
export var seeHowImg = "about-module--see-how-img--c490f";
export var teamMember = "about-module--team-member--6762c";
export var titleBlock = "about-module--title-block--f0dc9";
export var valueTitle = "about-module--value-title--11e17";
export var videoPhoto = "about-module--video-photo--f61f4";
export var weLocated = "about-module--we-located--e8917";
export var weLocatedContent = "about-module--we-located-content--8458e";
export var whoSubtitle = "about-module--who-subtitle--f6e67";
export var whoText = "about-module--who-text--a4ef3";
export var woWe = "about-module--wo-we--6f771";
export var woWeBlock = "about-module--wo-we-block--131eb";
export var woWeText = "about-module--wo-we-text--36dae";