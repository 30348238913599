import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import * as s from "../styles/about.module.css";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Seo from "../components/seo";
// markup
const AboutUsPage = ({ location, data }) => {
  const [opened, setOpened] = useState(false);

  const toggleOpened = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  const additionalText = (
    <>
      <p>
        The reason was my lack of experience and knowledge. It also distracted
        me from what I enjoyed doing most.
      </p>
      <p>
        The majority of the restaurant owners I talk to are very passionate
        about making wonderful dishes and communicating with their customers.
        Marketing, ordering, and managing online presences are the 3 of the most
        important points that are often overlooked or simply not being done
        because of time and financial constraints.
      </p>
      <p>
        My goal with orders.co is to help small and medium-size restaurants grow
        through our all-in-one delivery and marketing platform. I am sure you
        can compete with the industry leaders because all you need is a growth
        mindset and your delicious meal!
      </p>
    </>
  );
  return (
    <>
      <Seo
        title={"About Us"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={"#"}></Header>
      <div className={s.bodyShadow}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="shadow"
          src="../images/AboutUs/shadow.svg"
        />
      </div>
      <main className={s.aboutUsPage}>
        <section className={s.woWeBlock}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="text-center">Who We Are </h1>
                <p className={`${s.whoSubtitle} text-center`}>
                  We help small businesses learn, grow, and succeed
                </p>
                {/* <div className="row justify-content-center align-items-center">
                  <StaticImage
                    className={s.seeHowImg}
                    placeholder="none"
loading="eager"
                    alt="video-play-image"
                    src="../images/AboutUs/play.svg"
                  />
                  <a className={s.seeHow} href="#see">
                    See How It Works
                  </a>
                </div> */}
                <div className={s.videoPhoto}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="orders.co team"
                    src="../images/AboutUs/orders.co-team.png"
                  />
                </div>
                <div className={s.woWeText}>
                  <p>
                    Being a small business is hard. Growing your restaurant or
                    store is even harder. I feel the hardship you face, the
                    disappointment, and the endless working hours.
                  </p>
                  <p>
                    I used to operate a small store and every month was a
                    constant iteration of strategies, testing new tools and
                    software and actually getting disappointed. I remember
                    spending 4 months building a website and then realizing that
                    it is not designed correctly.
                  </p>
                  <div
                    className={`${opened ? "d-block" : ""} d-none d-md-block`}
                  >
                    {additionalText}
                  </div>
                </div>
                <div className="d-block d-md-none text-center">
                  <button
                    className={s.learnMore}
                    href="#"
                    onClick={toggleOpened}
                  >
                    {opened ? "Hide" : "Learn More"}
                    <svg
                      className="ml-2"
                      width="19"
                      height="10"
                      viewBox="0 0 19 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.815 3.293H1.03287C0.908574 3.293 0.789368 3.34238 0.701477 3.43027C0.613586 3.51816 0.564209 3.63737 0.564209 3.76166L0.564209 5.94875C0.564209 6.0103 0.576331 6.07124 0.599884 6.1281C0.623436 6.18497 0.657958 6.23663 0.701477 6.28015C0.744996 6.32367 0.796661 6.35819 0.853522 6.38174C0.910383 6.4053 0.971326 6.41742 1.03287 6.41742H12.815V8.21396C12.8154 8.39906 12.8705 8.57993 12.9734 8.73375C13.0764 8.88758 13.2226 9.00747 13.3936 9.07831C13.5646 9.14916 13.7528 9.16778 13.9344 9.13183C14.116 9.09589 14.2828 9.00699 14.414 8.87633L17.7727 5.51759C17.8601 5.4305 17.9294 5.32703 17.9767 5.2131C18.024 5.09916 18.0484 4.97701 18.0484 4.85365C18.0484 4.73028 18.024 4.60813 17.9767 4.4942C17.9294 4.38026 17.8601 4.27679 17.7727 4.18971L14.414 0.830964C14.2828 0.70031 14.116 0.611405 13.9344 0.57546C13.7528 0.539515 13.5646 0.558138 13.3936 0.628983C13.2226 0.699827 13.0764 0.819719 12.9734 0.973543C12.8705 1.12737 12.8154 1.30823 12.815 1.49334V3.293Z"
                        fill="#FF6B00"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="bg-mobile pb-5 pt-4 pb-xl-0 mt-4 mt-xl-5"
          style={{
            backgroundColor: "#F7F7F7",
          }}
        >
          <section className={`${s.valueTitle} title-block`}>
            <div className="container">
              <h2 className={`text-center`}>Our Values</h2>
            </div>
          </section>
          <section className={s.questionBlock}>
            <div className="container">
              <div className="">
                <div className={`${s.questionTile} ${s.questionTile1}`}>
                  <div className={s.questionNumber}>
                    <h2>1</h2>
                  </div>
                  <div className={s.ourQuestion}>
                    <p>
                      We help small and medium-sized restaurants innovate and
                      lead rather than playing catch-up.
                    </p>
                  </div>
                </div>

                <div
                  className={`${s.questionTile} ${s.questionTile2} ${s.questionTile2}`}
                >
                  <div className={s.questionNumber}>
                    <h2>2</h2>
                  </div>
                  <div className={s.ourQuestion}>
                    <p>
                      We facilitate the addition of extra revenue channels for
                      restaurants through software and technology solutions.
                    </p>
                  </div>
                </div>
                <div
                  className={`${s.questionTile} ${s.questionTile3} ${s.questionTile3}`}
                >
                  <div className={s.questionNumber}>
                    <h2>3</h2>
                  </div>
                  <div className={s.ourQuestion}>
                    <p>
                      We are technology enablers and allow restaurants to
                      concentrate on the thing they enjoy the most - cooking
                      delicious meals
                    </p>
                  </div>
                </div>
                <div
                  className={`${s.questionTile} ${s.questionTile4} ${s.questionTile4}`}
                >
                  <div className={s.questionNumber}>
                    <h2>4</h2>
                  </div>
                  <div className={s.ourQuestion}>
                    <p>
                      We enable restaurants to focus on what they do best -
                      making delicious food and servicing their customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className={s.ourFounding}>
          <div className="container">
            <h3 className="title">
              <span className={s.greenTitle}>Our founding </span>
              <span className={s.orangeTitle}>team</span>{" "}
            </h3>
          </div>
        </section>
        <section className={s.ourFoundingContent}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div
                className={`${s.teamMember} col-4 col-md-4 col-lg-4 col-sm-4`}
              >
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  className="w-100"
                  src="../images/AboutUs/Arsen-Stepanyan.png"
                  alt="Arsen Stepanyan"
                  objectFit="cover"
                  objectPosition="top center"
                />
                <div className={s.memberInfo}>
                  <a
                    className="text-decoration-none"
                    href="https://www.linkedin.com/in/arsen-stepanyan-50b7431b2/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4>Arsen Stepanyan</h4>
                  </a>
                  <p>CEO & Co-Founder</p>
                </div>
              </div>
              <div
                className={`${s.teamMember} col-4 col-md-4 col-lg-4 col-sm-4`}
              >
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  className="w-100"
                  src="../images/AboutUs/Ashot-Bayatyan.png"
                  alt="Ashot Bayatyan"
                  objectFit="cover"
                  objectPosition="top center"
                />
                <div className={s.memberInfo}>
                  <a
                    className="text-decoration-none"
                    href="https://www.linkedin.com/in/ashot-bayatyan-3837421b2/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4>Ashot Bayatyan</h4>
                  </a>
                  <p>CTO & Co-Founder</p>
                </div>
              </div>
              <div
                className={`${s.teamMember} col-4 col-md-4 col-lg-4 col-sm-4`}
              >
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  className="w-100"
                  src="../images/AboutUs/Harry-Ayvazyan.png"
                  alt="Harry Ayvazyan"
                  objectFit="cover"
                  objectPosition="top center"
                />
                <div className={s.memberInfo}>
                  <a
                    className="text-decoration-none"
                    href="https://www.linkedin.com/in/hrant-harry-ayvazyan-96077341/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4>Harry Ayvazyan</h4>
                  </a>
                  <p> Head of Business Development</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={s.weLocated}>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h2 className="title text-left">WE ARE LOCATED IN</h2>
              </div>
            </div>
          </div>
        </section>
        <section className={s.weLocatedContent}>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="row justify-content-center">
                  <div className="col-6 col-lg-6 col-xl-5 col-md-6 col-sm-6 text-center">
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      className="mw-100"
                      src="../images/AboutUs/Los-Angeles.png"
                      alt="LA California"
                    />
                    <div className={`${s.locationText} mt-4`}>
                      <p>Los Angeles County, California</p>
                      <p>United States</p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-6 col-xl-5 offset-xl-2 col-md-6 col-sm-6 text-center">
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      className="mw-100"
                      src="../images/AboutUs/Yerevan.png"
                      alt="Yerevan Armenia"
                    />
                    <div className={`${s.locationText} mt-4`}>
                      <p>Yerevan, Armenia</p>
                      <p>Europe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer scrollToForm={"#"}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "about-us" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default AboutUsPage;
